import { useEffect, useRef } from 'react';

export const useWindowDimensions = () => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = window.innerWidth;
    dimensions.current.height = window.innerHeight;
  }, []);

  return dimensions.current;
};

/**
  Designs given to us were based on 1920 pixel width.
  Helper utility to avoid us having to manually calculate
  this value, especially if there are any updates or changes.
**/
export const convertToVW = (pixels: number) => {
  const vwUnits = (pixels / 1920) * 100;
  return `${vwUnits}vw`;
};

/**
  Mobile designs given to us were based on 750 pixel width. Helper utility to
  avoid us having to manually calculate this value, especially if there are
  any updates or changes.
**/
export const convertToMobileVW = (pixels: number) => {
  const vwUnits = (pixels / 750) * 100;
  return `${vwUnits}vw`;
};
