import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Text, Font, SubHeader } from 'components/Text/Text';
import { BREAKPOINTS } from 'shared/constants';
import { white, slate, black } from 'shared/colors';
import { convertToMobileVW, convertToVW } from 'shared/utils';

interface Props {
  isModalOpen: boolean;
  afterOpenModal: () => void;
  closeModal: () => void;
}

Modal.setAppElement('#___gatsby');

const submitEmail = async (emailData: any) => {
  const url = process.env.GATSBY_ITERABLE_EMAIL_API_URL as string;
  const data = new URLSearchParams();
  data.append('email', emailData.email);
  data.append('zipCode', emailData.zipCode);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });

  return await response.text();
};

const EmailModal: React.FC<Props> = ({
  isModalOpen,
  afterOpenModal,
  closeModal,
}: Props) => {
  const [isSubmittedSuccess, setIsSubmittedSuccess] = useState<boolean>(false);
  const [isSubmittedError, setIsSubmittedError] = useState<boolean>(false);
  const [isSubmittedDuplicate, setIsSubmittedDuplicate] = useState<boolean>(
    false
  );

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => {
    submitEmail(data).then((text) => {
      if (text.includes('Thank you')) {
        setIsSubmittedSuccess(true);
      } else if (text.includes('is already subscribed')) {
        setIsSubmittedDuplicate(true);
      } else {
        setIsSubmittedError(true);
      }
    });
  };

  useEffect(() => {
    setIsSubmittedSuccess(false);
    setIsSubmittedError(false);
    setIsSubmittedDuplicate(false);
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Email Sign up"
      className={css(styles.body)}
      style={{
        overlay: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <FontAwesomeIcon
        icon={faTimes}
        className={css(styles.icon)}
        onMouseDown={closeModal}
        onKeyUp={(e) =>
          e.key === 'Enter' || e.key === ' ' ? closeModal() : null
        }
        tabIndex={0}
      />
      {isSubmittedSuccess && (
        <div className={css(styles.thankYouWrapper)}>
          <Text text="Thank you for subscribing" extraStyle={styles.header} />
        </div>
      )}
      {isSubmittedError && (
        <div className={css(styles.thankYouWrapper)}>
          <Text
            text="Sorry, there has been an error. Please check your information and try again."
            extraStyle={styles.header}
          />
        </div>
      )}
      {isSubmittedDuplicate && (
        <div className={css(styles.thankYouWrapper)}>
          <Text
            text="It looks like you've already subscribed!"
            extraStyle={styles.header}
          />
        </div>
      )}
      {!isSubmittedSuccess && !isSubmittedError && !isSubmittedDuplicate && (
        <>
          <form className={css(styles.form)} onSubmit={handleSubmit(onSubmit)}>
            <SubHeader text="Subscribe" extraStyle={styles.h2} />

            <div className={css(styles.labelContainer)}>
              <label htmlFor="email" className={css(styles.text)}>
                Enter Email:
              </label>
              {errors.email && (
                <Text
                  text="Valid email is required."
                  font={Font.TitlingGothicFBCompressedRegular}
                  extraStyle={styles.text}
                />
              )}
            </div>
            <input
              name="email"
              id="email"
              type="email"
              className={css(styles.input)}
              ref={register({ required: true })}
            />

            <div className={css(styles.labelContainer)}>
              <label htmlFor="zipCode" className={css(styles.text)}>
                Enter Zip Code:
              </label>
              {errors.zipCode && (
                <Text
                  text="Valid zip code is required."
                  extraStyle={styles.text}
                />
              )}
            </div>
            <input
              name="zipCode"
              id="zipCode"
              type="tel"
              className={css(styles.input)}
              ref={register({ required: true })}
            />

            <p className={css(styles.disclaimer)}>
              By checking this box and clicking "subscribe", I authorize Caliva
              or its subsidiaries, affiliates, agents, or service providers, to
              send me text (SMS) messages, using automated dialing technology
              for marketing and informational purposes, at the number I
              provided. I understand that message and data rates may apply, and
              that I am not required to give this consent as a condition of
              purchase. I understand that I can revoke this consent at any time.
            </p>
            <button className={css(styles.button)}>
              <Text text="Subscribe" extraStyle={styles.buttonText} />
            </button>
          </form>
        </>
      )}
    </Modal>
  );
};

export default EmailModal;

const styles = StyleSheet.create({
  body: {
    position: 'relative',
    backgroundColor: black,
    zIndex: 1000,
    width: '490px',
    height: 'auto',
    overflow: 'auto',
    margin: '0 auto',
    padding: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
    border: '1px solid #fff',

    [BREAKPOINTS.MOBILE]: {
      width: 'calc(100vw - 40px)',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: 0,
  },
  input: {
    fontSize: '12px',
    lineHeight: 1.2,
    letterSpacing: 0.07,
    height: 40,
    width: '100%',
    backgroundColor: 'transparent',
    color: white,
    padding: '0 .5rem',
    margin: '0 0 20px 0',
    border: `1px solid ${white}`,
    ':focus': {
      outline: 'none',
      color: black,
      backgroundColor: white,
    },
    fontFamily: 'Titling Gothic FB Normal Regular',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 12,
      width: '100%',
      marginTop: '.75rem',
    },
  },
  thankYouWrapper: {
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  header: {
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    textAlign: 'center',
    [BREAKPOINTS.MOBILE]: {
      fontSize: '12px',
      textAlign: 'center',
    },
  },
  button: {
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    width: '100%',
    height: convertToVW(123),
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease-in-out',
    ':focus': {
      border: '1px solid white',
      backgroundColor: black,
    },
    ':hover': {
      backgroundColor: white,
      transition: 'background 0.3s ease-in-out',
    },
    ':hover > p': {
      color: black,
      transition: 'color 0.3s ease-in-out',
    },
  },
  buttonText: {
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
  },
  icon: {
    position: 'absolute',
    color: slate,
    width: 20,
    height: 20,
    right: 14,
    top: 14,
    cursor: 'pointer',
    zIndex: 1,
    ':focus': {
      outline: `2px dotted ${white}`,
    },
    [BREAKPOINTS.TABLET]: {
      top: 15,
    },
    [BREAKPOINTS.MOBILE]: {
      height: 15,
      right: 18,
      top: 20,
    },
  },
  thankYouText: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: white,
    alignSelf: 'flex-start',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
  },
  text: {
    fontSize: '12px',
    color: white,
    alignSelf: 'flex-start',
    lineHeight: 1.2,
    fontFamily: 'Titling Gothic FB Normal Regular',
    [BREAKPOINTS.TABLET]: {
      fontSize: convertToMobileVW(18),
    },
  },
  label: {
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {
      fontSize: convertToMobileVW(18),
    },
  },
  h2: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: white,
    alignSelf: 'flex-start',
    marginBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: convertToMobileVW(17),
    },
    [BREAKPOINTS.MOBILE]: {
      fontSize: 12,
    },
  },
  checkboxContainer: {
    border: '1px solid #fff',
    width: '20px',
    height: '20px',
  },
  checkmark: {
    width: '20px',
    height: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checked: {
    backgroundColor: white,
  },
  default: {
    backgroundColor: 'transparent',
  },
  checkSpan: {
    border: '1px solid #fff',
    width: '20px',
    height: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    marginBottom: '20px',
  },
  disclaimer: {
    fontSize: '12px',
    color: white,
    alignSelf: 'flex-start',
    lineHeight: 1.2,
    fontFamily: 'Titling Gothic FB Normal Regular',
    marginBottom: '20px',
  },
});
