import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyleSheet, css } from 'aphrodite';
import { Font, SmallLinkText, MobileLinkText } from 'components/Text/Text';
import { white } from 'shared/colors';
import { HeaderData } from 'types/shared';
import { BREAKPOINTS } from 'shared/constants';
import TidalPlayer from 'components/Footer/TidalPlayer';

interface Props {
  isModalOpen?: boolean;
}

const Header = ({ isModalOpen }: Props) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const iframeRef: React.MutableRefObject<HTMLIFrameElement | null> = useRef(
    null
  );

  const data = useStaticQuery(graphql`
    query HeaderObjectQuery {
      contentfulHeaderObject {
        links
        logo {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `);

  // Handler for messages sent via postMessage from the Caliva Vue codebase
  const handleMessage = (event: MessageEvent<string>): void => {
    switch (event.data) {
      case 'toMonogramCheckout': {
        window.top.location.href = '/checkout';
        break;
      }
      case 'toMonogramShop': {
        window.top.location.href = '/products';
        break;
      }
      case 'noLocationSelected': {
        setIsSidebarVisible(() => true);
        break;
      }
      case 'itemAddedToCart': {
        if (isSidebarVisible && iframeRef.current) {
          iframeRef.current.src = iframeRef.current.src;
          break;
        }
        setIsSidebarVisible(() => true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCookieMessage = (event: MessageEvent): void => {
    if (event.data.id === 'LocationUpdate') {
      // Expire cookie after 1 month
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + 30 * 24 * 60 * 60 * 1000);
      const cookieExpires = `expires=${expiryDate.toUTCString()}`;

      const calivaZipCookie = {
        cookieName: 'CalivaZip',
        cookieValue: event.data.CalivaZip,
        cookieExpires,
      };
      const calivaModeCookie = {
        cookieName: 'CalivaMode',
        cookieValue: event.data.CalivaMode,
        cookieExpires,
      };
      const calivaShopCookie = {
        cookieName: 'CalivaShop',
        cookieValue: event.data.CalivaShop,
        cookieExpires,
      };
      const calivaModalActiveCookie = {
        cookieName: 'CalivaModalActive',
        cookieValue: 'false',
        cookieExpires,
      };

      const createCookie = (cookieObject: {
        cookieName: string;
        cookieValue: string;
        cookieExpires: string;
      }): void => {
        const { cookieName, cookieValue, cookieExpires } = cookieObject;
        document.cookie = `${cookieName}=${cookieValue};${cookieExpires};path=/;`;
      };

      createCookie(calivaZipCookie);
      createCookie(calivaModeCookie);
      createCookie(calivaShopCookie);
      createCookie(calivaModalActiveCookie);
    }
  };

  const [outputStockPrice, setStockPrice] = useState('');
  const [outputStockPercent, setStockPercent] = useState('');

  useEffect(() => {
    fetch(
      `https://finnhub.io/api/v1/quote?symbol=GRAMF&token=c4igo7qad3if6e3ec89g`
    )
      .then((response) => response.json())
      .then((stockPrice) => {
        console.log('This is your stock data', stockPrice.c);
        let currentPrice = stockPrice.c.toFixed(2);
        setStockPrice(`${currentPrice}`);
        let stockPercent =
          (100 * ((stockPrice.c - stockPrice.pc) / stockPrice.pc) <= 0
            ? ''
            : '+') +
          (100 * ((stockPrice.c - stockPrice.pc) / stockPrice.pc)).toFixed(2);
        setStockPercent(`${stockPercent}`);
      });

    window.addEventListener('message', handleMessage);
    window.addEventListener('message', handleCookieMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('message', handleCookieMessage);
    };
  }, [isSidebarVisible]);

  const headerData: HeaderData = data.contentfulHeaderObject;
  const navigationLinks = headerData.links;

  const [toggleNav, setToggleNav] = useState(false);
  const showNav = () => setToggleNav(!toggleNav);

  return (
    <header className={css(styles.container, isModalOpen && styles.hideHeader)}>
      <div className={css(styles.navLeftContainer)}>
        <Link to="/" className={css(styles.logoWrapper)}>
          <Img
            className={css(styles.logo)}
            alt="Monogram logo"
            fluid={headerData.logo.fluid}
          />
        </Link>

        <div className={css(styles.tidalContainer)}>
          <TidalPlayer height={'80px'} />
        </div>
      </div>

      {!toggleNav && (
        <FontAwesomeIcon
          icon={faBars}
          size="1x"
          className={css(styles.mobileMenuBtn)}
          onClick={showNav}
        />
      )}

      {toggleNav && (
        <FontAwesomeIcon
          icon={faTimes}
          size="1x"
          className={css(styles.mobileMenuBtn)}
          onClick={showNav}
        />
      )}

      {toggleNav && (
        <div id="navigation" className={css(styles.mobileMenu)}>
          {navigationLinks.map((link) => {
            return (
              <Link
                to={`/${link}`}
                key={`header-${link}`}
                className={css(styles.mobileLink)}
                activeClassName={css(styles.activeMobileLink)}
                onClick={showNav}
              >
                <MobileLinkText
                  extraStyle={styles.mobileLinkText}
                  text={link}
                />
              </Link>
            );
          })}
        </div>
      )}

      <a
        href="https://ir.theparent.co/overview/default.aspx"
        target="_blank"
        className={css(styles.stockContainer)}
      >
        <p id="output" className={css(styles.stockText)}>
          $GRAMF
          <span className={css(styles.stockSpan)}>{outputStockPercent}%</span>
          <span className={css(styles.stockSpan)}>${outputStockPrice}</span>
        </p>
      </a>

      <nav className={css(styles.linksContainer)}>
        {navigationLinks.map((link) => {
          return (
            <Link
              to={`/${link}`}
              key={`header-${link}`}
              className={css(styles.link)}
              activeClassName={css(styles.activeLink)}
            >
              <SmallLinkText extraStyle={styles.linkText} text={link} />
            </Link>
          );
        })}
      </nav>
    </header>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    display: 'flex',
    height: '120px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${white}`,
    zIndex: 100,
    paddingLeft: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    [BREAKPOINTS.TABLET]: {
      height: '90px',
    },
  },
  hideHeader: {
    display: 'none',
    zIndex: 0,
  },
  tidalContainer: {
    height: '80px',
    width: '30vw',
    marginLeft: '20px',
    marginTop: '0px',
    [BREAKPOINTS.TABLET]: {
      display: 'none',
    },
  },
  linksContainer: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    marginRight: '20px',
    marginBottom: '20px',

    [BREAKPOINTS.SA_INDEX_FIX]: {
      display: 'none',
    },
  },
  activeLink: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: `none`,
    float: 'left',
  },
  linkText: {
    marginLeft: '30px',
    height: '20px',
    fontSize: '12px',
    transition: 'background-color 0.5s ease',
    borderBottom: `2px solid transparent`,
    ':hover': {
      borderBottom: `2px solid ${white}`,
    },
    [BREAKPOINTS.TABLET]: {},
    [BREAKPOINTS.MOBILE]: {
      fontSize: 8,
    },
  },
  navLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: '20px',
    left: '20px',
  },
  logo: {
    width: '85px',
    height: '80px',
    transition: 'color 0.5s ease',
    filter: 'brightness(10)',
    [BREAKPOINTS.TABLET]: {
      width: '53px',
      height: '50px',
    },
  },
  logoWrapper: {
    [BREAKPOINTS.TABLET]: {
      padding: 0,
    },
  },
  stockContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    textDecoration: `none`,

    [BREAKPOINTS.SA_INDEX_FIX]: {
      top: 'initial',
      bottom: 20,
    },
  },
  stockText: {
    fontSize: '12px',
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: 1.2,
    textTransform: 'uppercase',

    [BREAKPOINTS.MOBILE]: {
      fontSize: 8,
    },
  },
  stockSpan: {
    marginLeft: 30,
  },
  mobileMenuBtn: {
    display: 'none',
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: white,

    [BREAKPOINTS.SA_INDEX_FIX]: {
      display: 'block',
    },
  },
  mobileMenu: {
    position: 'fixed',
    top: '120px',
    left: 0,
    width: '100vw',
    height: 'calc(100vh - 90px)',
    backgroundColor: 'black',

    [BREAKPOINTS.TABLET]: {
      top: '90px',
    },
  },
  mobileLink: {
    display: 'block',
    marginLeft: '20px',
    marginTop: '20px',
    lineHeight: 1,
    textDecoration: 'none',
  },
  activeMobileLink: {
    fontWeight: 500,
  },
});

export default Header;
