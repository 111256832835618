import React, { useState, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'gatsby';
import { Font, Text } from 'components/Text/Text';
import SVGMonoIcon from 'components/icons/SVGMonoIcon';
import SVGGramIcon from 'components/icons/SVGGramIcon';
import { white, granite, black } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import EmailModal from 'components/EmailModal/EmailModal';
import SmallVideoPauseButton from 'components/SmallVideoPauseButton/SmallVideoPauseButton';
import { convertToMobileVW, convertToVW } from 'shared/utils';

interface Props {
  video: {
    file: {
      url: string;
    };
  };
  buttonText: string;
  hasTextOverlay: boolean;
  isProcessCta: boolean;
  isModalOpen?: boolean;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoSection = ({
  video,
  buttonText,
  hasTextOverlay,
  isProcessCta,
  isModalOpen,
  setIsModalOpen,
}: Props) => {
  const vidRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoPaused, setIsVideoPaused] = useState<boolean | undefined>(
    vidRef.current?.paused
  );
  const [scrollPosition, setScrollPosition] = useState({
    xPosition: 0,
    yPosition: 0,
  });

  // Modal handlers
  const openModal = () => {
    setIsModalOpen && setIsModalOpen(true);
    setScrollPosition({
      xPosition: window.pageXOffset,
      yPosition: window.pageYOffset,
    });
  };
  const afterOpenModal = () => {
    document.documentElement.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen && setIsModalOpen(false);
    document.documentElement.style.overflow = 'visible';
    window.scrollTo(scrollPosition.xPosition, scrollPosition.yPosition);
  };

  return (
    <div
      className={css(
        styles.videoSection,
        isProcessCta && styles.videoFullBorder
      )}
    >
      <video
        autoPlay
        playsInline
        muted
        loop
        className={css(styles.video)}
        ref={vidRef}
        aria-hidden
      >
        <source src={video.file.url} />
        Sorry, your browser doesn't support embedded video
      </video>

      <SmallVideoPauseButton
        isVideoPaused={isVideoPaused}
        setIsVideoPaused={setIsVideoPaused}
        vidRef={vidRef}
        aria-hidden
      />

      <div className={css(styles.buttonWrapper)}>
        {!isProcessCta && (
          <>
            {hasTextOverlay && <SVGMonoIcon style={styles.svgIcon} />}
            <button className={css(styles.button)} onClick={() => openModal()}>
              <Text text={buttonText} extraStyle={styles.buttonText} />
            </button>
            {hasTextOverlay && <SVGGramIcon style={styles.svgIcon} />}
          </>
        )}
        {isProcessCta && (
          <Link to="/process">
            <button className={css(styles.button)} onClick={() => openModal()}>
              <Text text={buttonText} extraStyle={styles.buttonText} />
            </button>
          </Link>
        )}
      </div>

      {isModalOpen && (
        <EmailModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          afterOpenModal={afterOpenModal}
        />
      )}
    </div>
  );
};

export default VideoSection;

const styles = StyleSheet.create({
  videoSection: {
    fontFamily: 'Titling Gothic FB Wide',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vw * 9 / 16)',
    borderTop: `1px solid ${white}`,
    overflow: 'hidden',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  videoFullBorder: {
    borderBottom: `1px solid ${white}`,
  },
  video: {
    width: '100%',
    aspectRatio: '16 / 9',
    objectFit: 'cover',
    height: '100%',
    filter: 'brightness(0.4)',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: granite,
    width: '100%',
    height: '100%',
    [BREAKPOINTS.MOBILE]: {
      height: '100vh',
      width: '100%',
    },
  },
  buttonWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.TABLET]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  button: {
    color: white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
    ':focus': {
      border: '1px solid white',
      backgroundColor: black,
    },
    ':hover': {
      backgroundColor: white,
      transition: 'background 0.3s ease-in-out',
    },
    ':hover > p': {
      color: black,
      transition: 'color 0.3s ease-in-out',
    },
  },
  buttonText: {
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
  },
  svgIcon: {
    margin: '2.5rem auto',
    transform: 'scale(0.8)',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      margin: '1.8rem auto',
      transform: 'scale(1)',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      margin: '-1rem auto',
      transform: 'scale(0.6)',
    },
    [BREAKPOINTS.TABLET]: {
      margin: '-1rem auto',
      transform: 'scale(0.6)',
    },
  },
});
