import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'gatsby';

import CCPAArrow from 'components/icons/CCPAArrow';
import { black, white } from 'shared/colors';

const CCPABanner = () => {
  const [isBannerInfoVisible, setIsBannerInfoVisible] = useState(false);
  const [isCcpaAccepted, setIsCcpaAccepted] = useState(false);

  useEffect(() => {
    const accepted = localStorage.getItem('CCPA') === 'true';

    setIsCcpaAccepted(accepted);
  }, [isCcpaAccepted]);

  const handleShowBelowFold = () => {
    setIsBannerInfoVisible(!isBannerInfoVisible);
  };

  const handleButtonClick = () => {
    localStorage.setItem('CCPA', 'true');
    setIsCcpaAccepted(true);
  };

  return (
    <>
      {!isCcpaAccepted && (
        <aside className={css(styles.ccpaContainer)}>
          <div id="ccpa-preview-text" className={css(styles.textContainer)}>
            This website uses cookies to ensure you have the best experience.{' '}
            <span
              className={css(styles.cookieMore)}
              onClick={handleShowBelowFold}
            >
              Learn More
            </span>
            <button
              id="ccpa-accept-button"
              className={css(styles.acceptButton)}
              onClick={handleButtonClick}
            >
              OK
            </button>
          </div>

          <div
            className={css(
              styles.belowFoldHide,
              isBannerInfoVisible && styles.belowFoldShow
            )}
          >
            PERSONAL INFORMATION COLLECTION NOTICE Caliva and its affiliates
            (the “Company”) provide the following notice as required under the
            California Consumer Privacy Act of 2018 (CCPA). We collect personal
            information in the following categories and for the following
            business purposes:
            <table className={css(styles.ccpaTable)}>
              <tbody>
                <tr>
                  <th className={css(styles.tableBorder)}>Category</th>
                  <th className={css(styles.tableBorder)}>Purpose</th>
                </tr>
                <tr>
                  <td className={css(styles.tableBorder)}>Identifiers</td>
                  <td className={css(styles.tableBorder)}>
                    To fulfill or meet the reason you provided the information;
                    to create, maintain, customize, and secure your account with
                    us; to process your requests, purchases, transactions, and
                    payments; to provide you with support, respond to your
                    inquiries, and address your concerns and monitor and improve
                    our responses; to personalize your Website experience and to
                    deliver content, product, and service offerings relevant to
                    your interests; to help maintain the safety, security, and
                    integrity of our Website, products and services, databases
                    and other technology assets; to respond to law enforcement
                    requests and as required by applicable law, court order, or
                    governmental regulations.
                  </td>
                </tr>
                <tr>
                  <td className={css(styles.tableBorder)}>
                    Personal information categories listed in the California
                    Customer Records Statute (Cal. Civ. Code § 1798.80(e))
                  </td>
                  <td className={css(styles.tableBorder)}>
                    To fulfill or meet the reason you provided the information;
                    to process your requests, purchases, transactions, and
                    payments; to help maintain the safety, security, and
                    integrity of our Website, products and services, databases
                    and other technology assets; to respond to law enforcement
                    requests and as required by applicable law, court order, or
                    governmental regulations.
                  </td>
                </tr>
                <tr>
                  <td className={css(styles.tableBorder)}>
                    Protected classification characteristics under California or
                    federal law
                  </td>
                  <td className={css(styles.tableBorder)}>
                    To fulfill or meet the reason you provided the information;
                    to provide you with support, respond to your inquiries, and
                    address your concerns and monitor and improve our responses.
                  </td>
                </tr>
                <tr>
                  <td className={css(styles.tableBorder)}>
                    Commercial information
                  </td>
                  <td className={css(styles.tableBorder)}>
                    To process your requests, purchases, transactions, and
                    payments; to provide you with support, respond to your
                    inquiries, and address your concerns and monitor and improve
                    our responses; to personalize your Website experience and to
                    deliver content, product, and service offerings relevant to
                    your interests, including targeted offers via email or text
                    message (with your consent, where required by law); for
                    testing, research, analysis, and product development,
                    including to develop and improve our Website, products, and
                    services; to respond to law enforcement requests and as
                    required by applicable law, court order, or governmental
                    regulations.
                  </td>
                </tr>
                <tr>
                  <td className={css(styles.tableBorder)}>
                    Internet or other similar network activity
                  </td>
                  <td className={css(styles.tableBorder)}>
                    To personalize your Website experience and to deliver
                    content, product, and service offerings relevant to your
                    interests, including targeted offers via email or text
                    message (with your consent, where required by law); to help
                    maintain the safety, security, and integrity of our Website,
                    products and services, databases and other technology
                    assets; for testing, research, analysis, and product
                    development, including to develop and improve our Website,
                    products, and services.
                  </td>
                </tr>
                <tr>
                  <td className={css(styles.tableBorder)}>Geolocation data</td>
                  <td className={css(styles.tableBorder)}>
                    To create, maintain, customize, and secure your account with
                    us; to help maintain the safety, security, and integrity of
                    our Website, products and services, databases and other
                    technology assets; for testing, research, analysis, and
                    product development, including to develop and improve our
                    Website, products, and services; to respond to law
                    enforcement requests and as required by applicable law,
                    court order, or governmental regulations.
                  </td>
                </tr>
                <tr>
                  <td className={css(styles.tableBorder)}>
                    Inferences drawn from other personal information
                  </td>
                  <td className={css(styles.tableBorder)}>
                    To personalize your Website experience and to deliver
                    content, product, and service offerings relevant to your
                    interests, including targeted offers via email or text
                    message (with your consent, where required by law); for
                    testing, research, analysis, and product development,
                    including to develop and improve our Website, products, and
                    services.
                  </td>
                </tr>
              </tbody>
            </table>
            Please click on this link to our{' '}
            <Link className={css(styles.link)} to="/privacy">
              Privacy Policy
            </Link>
            . For accessibility information, please contact us using one of the
            contact methods listed in the Privacy Policy.
          </div>
        </aside>
      )}
    </>
  );
};

export default CCPABanner;

const styles = StyleSheet.create({
  ccpaContainer: {
    position: 'fixed',
    width: 'calc(100vw - 40px)',
    maxHeight: '80vh',
    maxWidth: '650px',
    overflow: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '20px',
    zIndex: 100,
    backgroundColor: black,
    color: white,
    border: '1px solid #fff',
    fontSize: 12,
    lineHeight: 1.5,
    padding: 0,
    fontFamily: 'Titling Gothic FB Normal Regular',
    margin: 0,
  },
  textContainer: {
    margin: 10,
    lineHeight: '30px',
    '@media (max-width: 750px)': {
      gridTemplateColumns: '1fr',
      paddingBottom: '10px',
      textAlign: 'center',
      lineHeight: '1.5',
    },
  },
  arrowContainer: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    transition: 'all 0.3s ease 0s',
    transform: 'rotate(180deg)',
  },
  rotateArrow: {
    transition: 'all 0.3s ease 0s',
    transform: 'rotate(0deg)',
  },
  belowFoldHide: {
    display: 'none',
    padding: '10px',
    fontSize: 12,
    lineHeight: 1.5,
  },
  belowFoldShow: {
    display: 'initial',
    padding: '10px',
    fontSize: 12,
    lineHeight: 1.5,
  },
  textAboveTable: {
    padding: '10px',
    fontSize: 12,
    lineHeight: 1.5,
  },
  ccpaTable: {
    fontSize: 12,
    lineHeight: 1.5,
    padding: '10px',
    border: `1px solid ${white}`,
    borderCollapse: 'collapse',
    borderSpacing: 2,
  },
  tableBorder: {
    border: `1px solid ${white}`,
    verticalAlign: 'top',
    padding: 4,
    margin: 0,
  },
  cookieMore: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  acceptButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 100,
    width: '100px',
    height: '30px',
    backgroundColor: white,
    color: black,
    border: `1px solid ${white}`,
    transition: 'all 0.3s ease',
    cursor: 'pointer',

    borderRadius: 0,
    ':hover': {
      backgroundColor: black,
      color: white,
      transition: 'all 0.3s ease',
    },
    '@media (max-width: 750px)': {
      position: 'relative',
      left: '0px',
      width: '100%',
    },
  },
  link: {
    color: white,
  },
});
