import React from 'react';
import { StyleSheet, css } from 'aphrodite';

interface Props {
  height: string;
}

const TidalPlayer = ({ height }: Props) => {
  // FIXME: currently switching colors causes React to re-render the playlist
  // let tidalSrc = '';
  // const randomNumber = Math.round(Math.random());
  // if (randomNumber === 1) {
  //   tidalSrc =
  //     'https://embed.tidal.com/playlists/c3078c42-abec-488e-9513-7a41214af098';
  // } else {
  //   tidalSrc =
  //     'https://embed.tidal.com/playlists/ff928ee1-bdff-4e34-87f6-17a832a1c5b8';
  // }
  const tidalSrc =
    'https://embed.tidal.com/playlists/ff928ee1-bdff-4e34-87f6-17a832a1c5b8';
  return (
    <iframe
      id="tidalPlayer"
      title="TIDAL music player"
      className={css(styles.tidalPlayer)}
      style={{ height: height }}
      src={tidalSrc}
    />
  );
};

export default TidalPlayer;

const styles = StyleSheet.create({
  tidalPlayer: {
    border: 'none',
    width: '100%',
  },
});
