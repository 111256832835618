import { default as React, useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { white } from 'shared/colors';
import { Style } from 'types/shared';

interface Props {
  key?: number | string;
  fontType?: string;
  text?: string;
  children?: React.ReactNode;
  extraStyle?: Style | Style[];
  font?: Font;
  aboveFold?: boolean;
}

const aboveFoldTextAnimationVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
  visibleHeader: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.7,
      ease: [0.25, 0.46, 0.45, 0.94],
    },
  },

  visibleSubHeader: {
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: 0.7,
      ease: [0.25, 0.46, 0.45, 0.94],
    },
  },
  visibleText: {
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: 1.2,
      ease: [0.25, 0.46, 0.45, 0.94],
    },
  },
};

const belowFoldTextAnimationVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
  visibleHeader: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.7,
      ease: [0.25, 0.46, 0.45, 0.94],
    },
  },
  visibleSubHeader: {
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: 0.7,
      ease: [0.25, 0.46, 0.45, 0.94],
    },
  },
  visibleText: {
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: 0.7,
      ease: [0.25, 0.46, 0.45, 0.94],
    },
  },
};

export const Header: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBCompressedRegular,
  ...rest
}: Props) => {
  return (
    <h1
      className={css(styles.header, font && styles[font], extraStyle)}
      {...rest}
    >
      {text}
    </h1>
  );
};

export const AnimatedHeader: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBCompressedRegular,
  aboveFold,
}) => {
  const [variant, setVariant] = useState<string>('hidden');
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setVariant('visibleHeader');
    } else {
      setVariant('hidden');
    }
  }, [inView]);

  return (
    <motion.h1
      ref={ref}
      className={css(styles.header, font && styles[font], extraStyle)}
      variants={
        aboveFold
          ? aboveFoldTextAnimationVariants
          : belowFoldTextAnimationVariants
      }
      initial="hidden"
      animate={variant}
    >
      {text}
    </motion.h1>
  );
};

export const SubHeader: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBWide,
}: Props) => {
  return (
    <h2 className={css(styles.subheader, font && styles[font], extraStyle)}>
      {text}
    </h2>
  );
};

export const AnimatedSubHeader: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBWide,
  aboveFold,
}) => {
  const [variant, setVariant] = useState<string>('hidden');
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setVariant('visibleSubHeader');
    } else {
      setVariant('hidden');
    }
  }, [inView]);
  return (
    <motion.h2
      ref={ref}
      className={css(styles.subheader, font && styles[font], extraStyle)}
      variants={
        aboveFold
          ? aboveFoldTextAnimationVariants
          : belowFoldTextAnimationVariants
      }
      initial="hidden"
      animate={variant}
    >
      {text}
    </motion.h2>
  );
};

export const FlavorText: React.FC<Props> = ({
  text,
  extraStyle,
  font,
}: Props) => {
  return (
    <p className={css(styles.flavorText, font && styles[font], extraStyle)}>
      {text}
    </p>
  );
};

export const Text: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBWide,
}: Props) => {
  return (
    <p className={css(styles.text, font && styles[font], extraStyle)}>{text}</p>
  );
};

export const AnimatedText: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBWide,
  aboveFold,
}) => {
  const [variant, setVariant] = useState<string>('hidden');
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setVariant('visibleText');
    } else {
      setVariant('hidden');
    }
  }, [inView]);

  return (
    <motion.p
      ref={ref}
      className={css(styles.text, font && styles[font], extraStyle)}
      variants={
        aboveFold
          ? aboveFoldTextAnimationVariants
          : belowFoldTextAnimationVariants
      }
      initial="hidden"
      animate={variant}
    >
      {text}
    </motion.p>
  );
};

export const SmallLinkText: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBWide,
}: Props) => {
  const linkName = text?.replace(/-/g, ' ');

  return (
    <p className={css(styles.smallLinkText, font && styles[font], extraStyle)}>
      {linkName}
    </p>
  );
};

export const MobileLinkText: React.FC<Props> = ({
  text,
  extraStyle,
  font = Font.TitlingGothicFBCompressedRegular,
}: Props) => {
  const linkName = text?.replace(/-/g, ' ');

  return (
    <p className={css(styles.mobileLinkText, font && styles[font], extraStyle)}>
      {linkName}
    </p>
  );
};

export enum Font {
  FontAwesome5Brands = 'FontAwesome5Brands',
  TitlingGothicFBCompressedRegular = 'TitlingGothicFBComp',
  TitlingGothicFBCompressedMedium = 'TitlingGothicFBCompMedium',
  TitlingGothicFBCompressedBold = 'TitlingGothicFBCompBold',
  TitlingGothicFBCompressedBlack = 'TitlingGothicFBCompBlack',
  TitlingGothicFBCompressedExtended = 'TitlingGothicFBCompressedExtended',
  TitlingGothicFBWide = 'TitlingGothicFBWide',
  TitlingGothicFBWideBold = 'TitlingGothicFBWideBold',
  TitlingGothicNarrowStandard = 'TitlingGothicNarrowStandard',
  TitlingGothicNormalRegular = 'TitlingGothicNormalRegular',
}

Header.defaultProps = {};

// Styles
// ----------------------------------------------------------------------------
const styles = StyleSheet.create({
  // fonts
  [Font.FontAwesome5Brands]: {
    fontFamily: 'Font Awesome 5 Brands',
  },
  [Font.TitlingGothicFBCompressedRegular]: {
    fontFamily: 'Titling Gothic FB Compressed',
    fontWeight: 'normal',
  },
  [Font.TitlingGothicFBCompressedMedium]: {
    fontFamily: 'Titling Gothic FB Compressed',
    fontWeight: 500,
  },
  [Font.TitlingGothicFBCompressedBold]: {
    fontFamily: 'Titling Gothic FB Compressed',
    fontWeight: 700,
  },
  [Font.TitlingGothicFBCompressedBlack]: {
    fontFamily: 'Titling Gothic FB Compressed',
    fontWeight: 900,
  },
  [Font.TitlingGothicFBCompressedExtended]: {
    fontFamily: 'Titling Gothic FB Extended',
    fontWeight: 500,
  },
  [Font.TitlingGothicFBWide]: {
    fontFamily: 'Titling Gothic FB Wide',
  },
  [Font.TitlingGothicFBWideBold]: {
    fontFamily: 'Titling Gothic FB Wide',
    fontWeight: 500,
  },
  [Font.TitlingGothicNarrowStandard]: {
    fontFamily: 'Titling Gothic FB Narrow Standard',
    fontWeight: 700,
  },
  [Font.TitlingGothicNormalRegular]: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    fontWeight: 400,
  },
  //
  header: {
    fontSize: 70,
    textTransform: 'uppercase',
  },
  subheader: {
    fontSize: 10,
    color: white,
    textTransform: 'uppercase',
  },
  text: {
    fontSize: 12,
    color: white,
  },
  flavorText: {
    fontSize: 24,
    color: white,
  },
  smallLinkText: {
    fontSize: 9,
    color: white,
    gridTemplateRows: 0.5,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  mobileLinkText: {
    fontSize: 40,
    color: white,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
});
