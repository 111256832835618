import { default as React } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Font } from 'components/Text/Text';
import { white, black, slate } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import { FooterData } from 'types/shared';
import VideoSection from 'components/VideoSection/VideoSection';
import TidalPlayer from './TidalPlayer';
import { Text } from 'components/Text/Text';

import { convertToVW, convertToMobileVW } from 'shared/utils';
const accessibleLogo = require('images/accessible-logo.png');
const bg = require('images/herov2.jpg');

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer = ({ isModalOpen, setIsModalOpen }: Props) => {
  const data = useStaticQuery(graphql`
    query FooterObjectQuery {
      contentfulFooterObject {
        video {
          file {
            url
          }
        }
        socialMediaLinks {
          icon
          link
        }
      }
    }
  `);

  const footerData = data.contentfulFooterObject as FooterData;

  return (
    <footer className={css(styles.container)}>
      <VideoSection
        video={footerData.video}
        buttonText="Subscribe"
        hasTextOverlay
        isProcessCta={false}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <section className={css(styles.musicPlayer)}>
        <TidalPlayer height="auto" />
      </section>
      <section className={css(styles.footerTextContainer)}>
        <ul className={css(styles.socialLinksContainer)}>
          {footerData.socialMediaLinks.map(({ link, icon }, idx) => (
            <li key={`social-link-${idx}`}>
              <a
                href={link}
                className={css(styles.socialLink)}
                aria-label={`link to ${link}`}
              >
                <FontAwesomeIcon
                  icon={['fab', icon]}
                  alt={`social media link for ${link}`}
                />
              </a>
            </li>
          ))}
        </ul>
        <div className={css(styles.legalContainer)}>
          <p>&copy; 2020 All Rights Reserved. Caliva.</p>
          <Link
            to="/ada/"
            target="_blank"
            rel="noopener noreferrer"
            className={css(styles.legalLink)}
          >
            Accessibility Statement
          </Link>{' '}
          |{' '}
          <Link
            to="/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className={css(styles.legalLink)}
          >
            Privacy Policy
          </Link>{' '}
          |{' '}
          <Link
            to="/tou/"
            target="_blank"
            rel="noopener noreferrer"
            className={css(styles.legalLink)}
          >
            Terms of Use
          </Link>{' '}
          |{' '}
          <Link
            to="/where-to-buy/"
            target="_blank"
            rel="noopener noreferrer"
            className={css(styles.legalLink)}
          >
            Where to Buy
          </Link>{' '}
          |{' '}
          <a
            href="https://theparent.co"
            target="_blank"
            className={css(styles.legalLink)}
            aria-label={`link to theparent.co`}
          >
            The Parent Company
          </a>
          {/* |{' '}
          <Link
            to="/faq/"
            target="_blank"
            rel="noopener noreferrer"
            className={css(styles.legalLink)}
          >
            FAQ
          </Link> */}
        </div>
        <p className={css(styles.nc3)}>
          This website is operated by NC3 Systems (BCC license no.
          C11-0000819-LIC ), which is responsible for its content.
        </p>
        <div className={css(styles.accessibleLogoContainer)}>
          <img
            className={css(styles.accessibleLogo)}
            src={accessibleLogo}
            alt="Reviewed by Accessible 360"
          />
        </div>
      </section>
    </footer>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  socialLinksContainer: {
    display: 'flex',
    listStyleType: 'none',
    justifyContent: 'space-between',
    margin: '1.875rem 0 0',
    zIndex: 1,
    [BREAKPOINTS.TABLET]: {
      justifyContent: 'center',
    },
  },
  musicPlayer: {
    height: 150,
    width: '100%',
    borderTop: '1px solid white',
    display: 'none',
    [BREAKPOINTS.TABLET]: {
      display: 'block',
    },
    [BREAKPOINTS.TIDAL_PLAYER_MOBILE]: {
      display: 'block',
      height: 96,
    },
  },
  socialLink: {
    fontSize: 32,
    padding: '0.625rem 1.125rem',
    color: white,
    transition: 'opacity 0.5s ease',
    ':hover': {
      opacity: 0.8,
    },
  },
  link: {
    textDecoration: 'none',
  },
  linkText: {
    fontSize: '10px',
    lineHeight: 1.2,
    color: slate,
    textTransform: 'uppercase',
    transition: 'color 0.5s ease',
    ':hover': {
      color: white,
    },
  },
  footerTextContainer: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    fontSize: '10px',
    fontWeight: 100,
    lineHeight: 1.5,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: black,
    color: white,
    borderTop: '1px solid white',
    zIndex: 1,
  },
  legalContainer: {
    marginBottom: '1.5rem',
  },
  legalLink: {
    color: white,
  },
  nc3: {
    marginBottom: '1.5rem',
    [BREAKPOINTS.MOBILE]: {
      padding: '0 1rem',
    },
  },
  accessibleLogoContainer: {
    marginTop: '1.25rem',
    paddingBottom: '1.25rem',
  },
  accessibleLogo: {
    height: 60,
    width: 60,
  },
  textWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      width: 'calc(100vw - 80px)',
    },
  },
  productCtaContainer: {
    borderTop: '1px solid white',
    position: 'relative',
    backgroundColor: black,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100vw',
    height: 'calc(100vw * 9 / 16)',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  productCta: {
    display: 'block',
    filter: 'brightness(0.5)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  buttonWrapper: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  button: {
    color: black,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
    ':focus': {
      border: '1px solid white',
      backgroundColor: white,
    },
    ':hover': {
      border: '1px solid black',
      backgroundColor: black,
      transition: '0.3s ease-in-out',
    },
    ':hover > p': {
      color: white,
      transition: 'color 0.3s ease-in-out',
    },
  },
  buttonText: {
    fontSize: 12,
    color: black,
    fontFamily: 'Titling Gothic FB Wide',
    [BREAKPOINTS.TABLET]: {},
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  title: {
    color: white,
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    paddingBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
});

export default Footer;
