export const BREAKPOINTS = {
  MOBILE: '@media (max-width: 480px)',
  TIDAL_PLAYER_MOBILE: '@media (max-width: 423px)',
  MATTE_MOBILE: '@media(max-width: 750px)',
  TABLET: '@media (max-width: 750px)',
  SMALL_DESKTOP: '@media (max-width: 1199px)',
  SATURN_FIX: '@media (max-width: 1060px)',
  WEB: '@media (min-width: 480px)',
  DESKTOP_LARGE: '@media (min-width: 1600px)',
  FOOTER_BREAK_12: '@media (min-width: 1200px)',
  FOOTER_BREAK_95: '@media (max-width: 950px)',
  SA_INDEX_FIX: '@media (max-width: 950px)',
  LAPTOP: '@media (max-width: 1400px)',
  SELECTOR: '@media (max-width: 840px)',
};

export const BACKGROUND_TYPE = {
  COLOR: 'color',
  IMAGE: 'image',
  VIDEO: 'video',
};
