import React from 'react';
import { StyleSheet, css } from 'aphrodite';

interface Props {
  style?: {};
}

const SVGMonoIcon = ({ style }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="452"
      height="193.612"
      className={css(style)}
    >
      <title>Decorative text. The first half of Monogram ("Mono")</title>
      <g id="Group_68" transform="translate(-18.581 -19.331)">
        <path
          id="Path_1"
          d="M144.8 208.36v-186a2.665 2.665 0 00-2.65-2.676h-24.157c-2.515 0-3.911.843-4.471 2.811L82.81 130.274h-1.12L50.136 22.493c-.56-1.969-1.956-2.811-4.471-2.811H21.231a2.66 2.66 0 00-2.65 2.676v186a2.66 2.66 0 002.65 2.676h21.783a2.66 2.66 0 002.65-2.676V87.217h1.679l24.568 86.957a3.627 3.627 0 003.635 2.811h12.288a3.638 3.638 0 003.635-2.811l24.568-86.957h1.673V208.36a2.66 2.66 0 002.65 2.676h21.789a2.665 2.665 0 002.651-2.676z"
          className={css(styles.svg)}
          transform="translate(0 1.907)"
        />
        <path
          id="Path_2"
          d="M156.923 208.36v-186a2.665 2.665 0 00-2.65-2.676h-20.947a2.661 2.661 0 00-2.65 2.676v132.393h-1.4L94.1 22.493c-.56-1.969-1.679-2.811-3.911-2.811H60.444a2.661 2.661 0 00-2.65 2.676v186a2.661 2.661 0 002.65 2.676h20.947a2.66 2.66 0 002.65-2.676V75.959h1.673l36.862 132.266c.56 1.969 1.679 2.811 3.911 2.811h27.785a2.665 2.665 0 002.651-2.676z"
          className={css(styles.svg)}
          transform="translate(213.05 1.907)"
        />
        <path
          id="Path_3"
          d="M116.995 138.653c18.431 0 32.153-8.3 37.64-24.9 1.956-5.918 2.741-11.451 2.741-34.765s-.785-28.84-2.741-34.765c-5.487-16.591-19.209-24.9-37.64-24.9-18.817 0-32.539 8.305-38.026 24.9-1.956 5.925-2.741 11.451-2.741 34.765s.785 28.846 2.741 34.765c5.487 16.601 19.209 24.9 38.026 24.9zM104.058 52.52c1.57-5.134 6.272-8.292 12.937-8.292 6.272 0 10.981 3.159 12.545 8.292 1.177 3.558 1.57 7.115 1.57 26.472s-.392 22.915-1.57 26.472c-1.563 5.134-6.272 8.292-12.545 8.292-6.665 0-11.367-3.159-12.937-8.292-1.171-3.558-1.563-7.109-1.563-26.472s.392-22.915 1.563-26.472z"
          className={css(styles.svg)}
          transform="translate(313.205)"
        />
        <path
          id="Path_4"
          d="M122.834 78.992c0-23.314-.785-28.84-2.747-34.765-5.487-16.591-19.209-24.9-37.634-24.9-18.817 0-32.539 8.305-38.026 24.9-1.962 5.925-2.747 11.451-2.747 34.765s.785 28.846 2.747 34.765c5.487 16.6 19.209 24.9 38.026 24.9 18.425 0 32.146-8.3 37.634-24.9 1.962-5.919 2.747-11.451 2.747-34.765zM95 105.465c-1.57 5.134-6.272 8.292-12.545 8.292-6.665 0-11.367-3.159-12.944-8.292-1.171-3.558-1.563-7.109-1.563-26.472s.392-22.915 1.563-26.472c1.576-5.134 6.279-8.292 12.944-8.292 6.272 0 10.975 3.159 12.545 8.292 1.177 3.558 1.57 7.115 1.57 26.472s-.395 22.914-1.57 26.472z"
          className={css(styles.svg)}
          transform="translate(125.5)"
        />
      </g>
    </svg>
  );
};

export default SVGMonoIcon;

const styles = StyleSheet.create({
  svg: {
    fill: '#fff',
  },
});
