import React from 'react';
import { StyleSheet, css } from 'aphrodite';

interface Props {
  style?: {};
}

const SVGGramIcon = ({ style }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="449.871"
      height="196.424"
      className={css(style)}
    >
      <title>Decorative text. The second half of Monogram ("Gram")</title>
      <g id="Group_67" transform="translate(-18.581 -413.618)">
        <path
          id="Path_5"
          d="M192.459 55.522H168.3c-2.515 0-3.911.843-4.471 2.811L133.12 166.114H132L100.446 58.333c-.56-1.969-1.949-2.811-4.465-2.811h-24.44a2.665 2.665 0 00-2.65 2.676v186a2.67 2.67 0 002.65 2.683h21.79a2.666 2.666 0 002.65-2.683V123.057h1.673l24.575 86.957a3.614 3.614 0 003.628 2.811h12.287a3.609 3.609 0 003.628-2.811l24.575-86.957h1.673V244.2a2.67 2.67 0 002.65 2.683h21.789a2.666 2.666 0 002.65-2.683v-186a2.66 2.66 0 00-2.65-2.678z"
          className={css(styles.svg)}
          transform="translate(273.342 360.632)"
        />
        <path
          id="Path_6"
          d="M66.868 55.128c-32.552 0-48.287 19.274-48.287 57.184v84.217c0 38.843 19.344 55.023 48.287 55.023 28.087 0 48.307-14.661 48.307-58.683v-46.133a2.67 2.67 0 00-2.65-2.683H69.77a2.67 2.67 0 00-2.65 2.683V168.4a2.665 2.665 0 002.65 2.676h15.311a1.334 1.334 0 011.325 1.338v21.455c0 19.686-2.708 30.319-18.984 30.39-14.417 0-18.489-7.134-18.489-27.811v-82.727c0-21.924 3.622-31.3 18.212-31.3 16.823 0 19.531 9.766 19.531 25.707v1.557a2.67 2.67 0 002.65 2.683h23.629a2.67 2.67 0 002.65-2.683v-4.046c.001-39.195-18.655-50.511-48.737-50.511z"
          className={css(styles.svg)}
          transform="translate(0 358.49)"
        />
        <path
          id="Path_7"
          d="M87.533 55.522H39.22a2.659 2.659 0 00-2.644 2.678v186a2.66 2.66 0 002.65 2.676h24.588a2.66 2.66 0 002.65-2.676l-.013-74.882a.667.667 0 01.663-.669h18.2c6.292 0 10.364 4.227 10.364 12.184v50.179c0 3.918.058 9.836.09 13.227a2.656 2.656 0 002.65 2.638h23.758a2.653 2.653 0 002.65-2.644c.019-3.725.051-10.66.051-16.006v-47.5c0-14.616-4.818-21.622-15.24-25.765 14.558-5.938 19.351-14.385 19.447-42.594.006-.309.006-12.68.006-12.68.001-26.854-14.313-44.166-41.557-44.166zm-5.545 86.391H67.777a1.33 1.33 0 01-1.325-1.338V84.156a1.33 1.33 0 011.325-1.338h15.6c13.117 0 16.662 8.086 16.9 17.71.013.45 0 14.777.013 16 .148 14.982-5.7 25.385-18.302 25.385z"
          className={css(styles.svg)}
          transform="translate(97.77 360.632)"
        />
        <path
          id="Path_8"
          d="M113.3 57.613a2.654 2.654 0 00-2.586-2.091H81.117a2.654 2.654 0 00-2.586 2.091L53.281 171.9a2.672 2.672 0 002.586 3.262h21.744a1.812 1.812 0 001.776-1.512l3.307-18.36h25.958l3.3 18.36a1.807 1.807 0 001.776 1.512h22.232a2.668 2.668 0 002.586-3.262zm-27.416 79.938l9.142-50.758a.659.659 0 011.3 0l9.135 50.758z"
          className={css(styles.svg)}
          transform="translate(188.183 360.632)"
        />
      </g>
    </svg>
  );
};

export default SVGGramIcon;

const styles = StyleSheet.create({
  svg: {
    fill: '#fff',
  },
});
