import { default as React, useState } from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import CCPABanner from 'components/CCPABanner/CCPABanner';
import 'components/normalize.css';

type Props = {
  children: React.ReactNode;
  pageContext: {
    layout: string;
  };
};

const Layout: React.FC<Props> = ({ children, pageContext }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Builds and appends AppOmni script tag to end of body element
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://a.omappapi.com/app/js/api.min.js';
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('data-account', '54508');
    script.setAttribute('data-user', '48335');

    document.body.appendChild(script);
  }, []);

  library.add(fab);

  if (pageContext.layout === 'noFooter') {
    return (
      <>
        <Header />
        {children}
      </>
    );
  }

  return (
    <>
      <Header isModalOpen={isModalOpen} />
      {children}
      <CCPABanner />
      <Footer isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
