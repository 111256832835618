import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Font, Text } from 'components/Text/Text';
import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';

interface Props {
  isVideoPaused: boolean | undefined;
  setIsVideoPaused: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  vidRef: React.MutableRefObject<HTMLVideoElement | null>;
  extraStyles?: {};
}

const VideoPauseButton = ({
  isVideoPaused,
  setIsVideoPaused,
  vidRef,
  extraStyles,
}: Props) => {
  return (
    <button
      className={css(styles.pauseButton, extraStyles && extraStyles)}
      onClick={() => {
        isVideoPaused ? vidRef.current?.play() : vidRef.current?.pause();
        setIsVideoPaused((isVideoPaused) => !isVideoPaused);
      }}
    >
      <Text
        text={isVideoPaused ? 'Play' : 'Pause'}
        extraStyle={styles.buttonText}
      />
    </button>
  );
};

export default VideoPauseButton;

const styles = StyleSheet.create({
  pauseButton: {
    top: '20px',
    right: '20px',
    fontFamily: 'Titling Gothic FB Wide',
    textTransform: 'uppercase',
    position: 'absolute',
    color: white,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    ':focus': {
      border: '1px solid white',
      backgroundColor: black,
    },
  },
  buttonText: {
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
  },
});
