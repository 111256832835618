// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-article-tsx": () => import("./../../../src/components/Article/Article.tsx" /* webpackChunkName: "component---src-components-article-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ada-tsx": () => import("./../../../src/pages/ada.tsx" /* webpackChunkName: "component---src-pages-ada-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-editorial-holding-tsx": () => import("./../../../src/pages/editorial/holding.tsx" /* webpackChunkName: "component---src-pages-editorial-holding-tsx" */),
  "component---src-pages-editorial-tsx": () => import("./../../../src/pages/editorial.tsx" /* webpackChunkName: "component---src-pages-editorial-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-high-tales-tsx": () => import("./../../../src/pages/high-tales.tsx" /* webpackChunkName: "component---src-pages-high-tales-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-tou-tsx": () => import("./../../../src/pages/tou.tsx" /* webpackChunkName: "component---src-pages-tou-tsx" */),
  "component---src-pages-where-to-buy-tsx": () => import("./../../../src/pages/where-to-buy.tsx" /* webpackChunkName: "component---src-pages-where-to-buy-tsx" */),
  "component---src-templates-product-detail-template-tsx": () => import("./../../../src/templates/productDetailTemplate.tsx" /* webpackChunkName: "component---src-templates-product-detail-template-tsx" */)
}

